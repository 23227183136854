.app-name {
  img {
    opacity: 0;
    translate: -8px 2px;
    scale: 79%;
    transition: translate 200ms ease-in-out, opacity 200ms ease-in-out;

  }
}

.layout-sidebar-default-logo {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}

.layout-sidebar-active {
  .layout-sidebar-default-logo {
    opacity: 0;
  }

  .layout-sidebar-active-logo {
    opacity: 1;
  }

  .app-name {
    img {
      opacity: 1;
      position: relative;
      z-index: 100;
      scale: 79%;
      translate: -35px 2px;
    }
  }
}

@media (min-width: 992px) {
  .layout-wrapper.layout-sidebar .layout-menu-wrapper .menu-logo .app-name {
    display: inline;
  }
}

.logo {
  &.p-link {
    position: relative;
  }
}

.layout-sidebar-active-logo {
  position: absolute;
  left: 0;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}