.p-button {
  background: var(--clr-brand);
  border-color: var(--clr-brand);

  &.p-button-outlined:focus {
    border-color: var(--primary-color-200) !important;
  }
}

.col {
  >.p-selectbutton {
    display: flex;

    * {
      flex-basis: 100%;
    }
  }
}

.table-pin {
  background: none;
  border: none;
  cursor: pointer;
  display: inline;
  padding-inline: 0.25em;

  svg {
    display: block;
    color: var(--clr-blue-600);
    transition: color ease-in-out 200ms;
  }

  &:hover {
    svg {

      color: var(--clr-blue-900);
    }
  }

  &--off {
    svg {

      color: var(--clr-gray-400)
    }
  }
}

.p-button-outlined.p-button {
  border-color: light-dark(var(--primary-color-highlight), var(--clr-info)) !important;
  color: light-dark(var(--primary-color-highlight), var(--clr-info)) !important;
}