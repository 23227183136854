.p-menu {
  width: 18rem;
}

.layout-wrapper.layout-sidebar .layout-main {
  margin-left: 63px;

  &:has(.layout-menu-wrapper.layout-sidebar-active) {
    margin-left: 200px;
  }
}

.layout-wrapper {
  background: unset; // Remove image
}

ul.p-toggleable-content-enter-done,
ul.p-toggleable-content-enter-active,
ul.p-toggleable-content-exit-active {
  li[role="menuitem"] {
    svg {
      display: none;
    }

    span {
      margin: 0 !important;
    }
  }
}

.layout-wrapper .layout-main {
  & > .layout-menu-wrapper,
  & > .layout-secondary-menu-wrapper,
  & > .layout-main-content {
    top: var(--header-height, 60px);
  }

  .layout-menu-wrapper {
    height: calc(100vh - 60px);
    background: var(--Primary-color-Dark, #023e66);

    * {
      color: #fff;
    }

    border-radius: 0;
    padding: 14px;
    width: 62px;

    &.layout-sidebar-active {
      // box-shadow: 15px 0px 36px 0px rgba(113, 120, 186, 0.27);
      width: 200px;
      top: 0;
      height: 100%;

      .menu-logo {
        padding: 4px 6px 0px 8px;
        overflow: hidden;
      }

      .sidebar-logo-container .release-version-note {
        cursor: pointer;
      }

      .layout-menu-container {
        padding: 0;

        .layout-menu {
          padding: 0px;

          & > li {
            margin: 7px 0;

            &:first-child {
              margin: 0;
            }
          }

          > .layout-root-menuitem {
            padding: 0;
          }
        }
      }

      > .layout-inline-menu {
        &.layout-inline-menu-active {
          height: 119.8px;
        }

        > .layout-inline-menu-action {
          padding: 0.5rem 1rem 0.5rem 1rem;
        }
      }
    }

    .menu-logo {
      padding: 4px 0px 0px 8px;
      overflow: hidden;
      transition: padding 0.2s;
    }

    .layout-menu-container {
      padding: 0;
      transition: padding 0.2s;
      font-family: Mulish, "Inter", sans-serif;

      .layout-menu {
        padding: 0px;

        > .layout-root-menuitem {
          padding: 0px;
          margin: 7px 0;

          &:first-child {
            margin: 0;
          }

          transition: padding 0.2s;

          > .p-ripple {
            border-radius: 8px;
            margin: 0;
            padding: 8px;

            > svg {
              height: 24px;
              width: 24px;
            }
          }

          > ul {
            .p-ripple {
              margin: 0;
              padding: 8px;

              > svg {
                height: 24px;
                width: 24px;
              }
            }

            > .active-menuitem {
              a,
              a:hover {
                background-color: #003e66;
              }
            }
          }

          &.active-menuitem {
            background-color: rgba(0, 62, 102, 0.07);

            > a {
              background-color: #0a5587 !important;
            }

            > .p-ripple {
              background-color: rgba(0, 62, 102, 0.07);
            }
          }
        }
      }
    }

    > .layout-inline-menu {
      height: 58px;
      transition: height 0.3s;

      > .layout-inline-menu-action {
        padding: 0.5rem 0rem 0.5rem 2px;
        transition: padding 0.2s;
      }
    }
  }

  .layout-secondary-menu-wrapper {
    background: linear-gradient(180deg, #f6f9fc 0%, #ececf9 100%);
    background: var(
      --gradient-sidebar,
      linear-gradient(180deg, #f6f9fc 0%, #ececf9 100%)
    );
    border-left: solid var(--gray-400) 1px;
    border-radius: 0px 40px 40px 0px;
    color: var(--gray-clr-gray-600, #4f5555);
    height: 100vh;
    left: -18rem;
    padding: 2.14286rem 1.57143rem;
    position: fixed;
    transition: left 0.5s;
    // width: 324px;
    width: 60px;
    z-index: 900;

    &.layout-secondary-sidebar-active {
      left: 4.5rem;
      box-shadow: 15px 0px 36px 0px rgba(113, 120, 186, 0.27);
    }

    .secondary-menu__header {
      display: flex;
      gap: 0.35714rem;
      color: var(--gray-clr-gray-500, #777);

      h2 {
        margin: 0;
        font-size: 1.42857rem;
      }

      svg {
        font-size: 1.71rem;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      height: 100%;
    }

    h2 {
      font-size: 1.42857rem;
      font-weight: 400;
    }

    h3 {
      color: var(--gray-clr-gray-600, #4f5555);
      font-size: 1.07143rem;
      font-weight: 400;

      & + div {
        display: flex;
        flex-direction: column;
        gap: 1.64286rem;
      }
    }

    .nav-sections-container {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      height: fit-content;
      -ms-overflow-style: none;
      overflow-x: hidden;
      overflow-y: auto;
      scrollbar-width: none;

      h3 {
        color: #4f5555;
        font-family: Inter;
        font-size: 17.5px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .p-divider.p-divider-horizontal {
        margin: 0;
      }

      .p-divider {
        margin-bottom: 0px !important;
      }

      .secondary-menu-item--active {
        background-color: #003e66;

        &:hover {
          background-color: #003e66;
        }
      }

      .link-sections-container {
        gap: 1.25rem;
        display: flex;
        flex-direction: column;

        h3 + div {
          gap: 0;
        }

        .secondary-menu-item--active {
          background-color: #003e66;
        }
      }

      &::-webkit-scrollbar {
        display: none;
        position: absolute;
        right: 0;
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgb(216, 216, 216);
        border-radius: 10px;
      }

      .field {
        margin: 0;

        span#input-text-global-week-picker,
        span.p-calendar {
          width: 100%;

          & > input {
            color: #6c757d;
          }

          &::after {
            position: absolute;
            font-family: primeicons;
            height: 21px;
            margin-left: auto;
            transform: translate3d(240px, 4px, 0px) scale(0.5);
            width: 21px;
          }
        }

        .p-dropdown span.p-placeholder,
        .p-multiselect .p-multiselect-label.p-multiselect-items-label {
          color: #6c757d;
        }
      }

      svg {
        font-size: 1.25rem;
      }
    }

    .secondary-menu-item {
      width: 100%;
      padding: 1rem;
      color: var(--sidebar-sdbr-item-text, #4f5555);
      border-radius: 8px;
      transition: background-color 100ms ease-in-out;

      a {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;
        color: var(--gray-clr-gray-600, #4f5555);

        > span:first-of-type {
          flex-grow: 1;
        }
      }

      &:hover {
        background: var(--gray-clr-gray-200, #e6e6e6);
        width: auto;
      }

      .p-badge {
        flex-shrink: 0;
      }
    }

    .secondary-menu-item--active {
      background-color: var(--clr-blue-700);

      a {
        color: white;
      }

      &:hover {
        background: var(--clr-blue-700);
        width: auto;
      }
    }

    .p-dropdown {
      width: 100%;
    }

    .p-multiselect {
      width: 100%;
    }
  }
}

.avatar--margin {
  margin-left: 0.5rem;
}

.globalInvFilter .p-multiselect-label {
  display: flex;
}

@media (min-width: 992px) {
  .layout-wrapper.layout-sidebar .layout-main.layout-filter-sidebar-active {
    margin-left: 63px;

    &:has(.layout-menu-wrapper.layout-sidebar-active) {
      margin-left: 200px;
    }
  }
}

@media (max-width: 991px) {
  .layout-wrapper .layout-main .layout-menu-wrapper {
    display: flex;
  }

  .layout-menu-wrapper
    .layout-menu-container
    .layout-menu
    > li.active-menuitem
    > a
    i {
    color: white;
  }

  .layout-wrapper.layout-sidebar {
    .layout-main {
      margin-left: 0px;
      overflow-x: hidden;

      .layout-menu-wrapper {
        padding: 14px 0px;

        .mobile-header-structure {
          align-items: center;
          display: flex;
          flex-direction: row;
          gap: 91.5585px;
          height: 5rem;
          margin: 10px 7px 0px;

          &__button {
            align-items: flex-end;
            display: flex;
            margin-left: 10px;
          }

          .menu-logo {
            padding: 0px;
          }
        }

        .layout-menu-container {
          padding: 0px 7px 0px;

          .layout-menu li.active-menuitem ul li.active-menuitem a::after {
            content: "";
          }
        }

        .layout-inline-menu {
          height: fit-content;
          padding-bottom: 0px;

          &.layout-inline-menu-active {
            height: fit-content;
          }

          > .layout-inline-menu-action {
            padding: 1rem 1.5rem 1rem !important;
          }
        }
      }

      .layout-secondary-menu-wrapper {
        border-radius: 0px;
        left: 0;
        padding-top: 15px;
        transition: transform 0.2s;
        width: 25.25rem;

        .secondary-go-back {
          background: transparent;
          display: flex;
          flex-direction: column;
          gap: 0px;
          height: fit-content;
          justify-content: flex-start;
          position: sticky;
          top: 0;

          &__options {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 6px;
            height: 46px;
            margin-bottom: 10px;
          }

          &__button {
            align-items: flex-end;
            display: flex;
          }

          &__label {
            align-items: center;
            display: flex;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            height: 24px;
            line-height: normal;
          }

          &__separator {
            border-bottom: 1px solid #dee2e6;
            margin-bottom: 21px;
            width: 100%;
          }
        }

        .secondary-menu {
          .nav-sections-container {
            .field {
              span#input-text-global-week-picker,
              span.p-calendar {
                &::after {
                  transform: translate3d(270px, 5px, 0px) scale(0.4);
                }
              }
            }

            .switch-between-two-filters .field {
              width: 17rem;
            }
          }
        }

        & > div {
          height: 87.5%;
        }
      }

      .layout-bg-overlay {
        background-color: rgba(0, 0, 0, 0.2);
        height: 100vh;
        position: absolute;
        width: 100vw;
        z-index: 800;
      }

      .layout-main-content {
        padding-top: 75px;

        .mobile-header-container {
          position: fixed;
          z-index: 700;

          &__row-container {
            align-items: center;
            background: linear-gradient(180deg, #f6f9fc 0%, #ececf9 100%);
            display: flex;
            flex-direction: row;
            // height: 55px;
            height: 60px;
            justify-content: space-between;
            padding: 15px 16px 15px 16px;
            transform: translate3d(-30px, -75px, 0px);
            width: 100vw;
          }

          &__button {
            align-items: flex-end;
            display: flex;
          }

          &__title-container {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 5px;
            width: fit-content;
          }

          &__title-label {
            color: #777;
            font-family: Inter;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          &__spacer {
            height: 21px;
            width: 21px;
          }
        }
      }

      &.layout-filter-sidebar-active {
        .layout-menu-wrapper.layout-sidebar-active {
          transform: translate3d(-100%, 0px, 0px);
        }
      }

      &.layout-content-active {
        .layout-menu-wrapper.layout-sidebar-active {
          box-shadow: none;
        }

        .layout-secondary-menu-wrapper {
          box-shadow: none;
          transform: translate3d(-100%, 0px, 0px);
        }

        .layout-bg-overlay {
          display: none;
        }
      }
    }
  }
}

@media (max-height: 500px) {
  ul.layout-menu {
    overflow-y: auto !important;
    // scrollbar-gutter: stable;
  }
}

.layout-menu-container {
  overflow-y: clip !important;
  overflow-x: visible !important;

  .p-tieredmenu.theme-menu {
    ul {
      overflow-y: visible;
      overflow-x: visible;
    }

    padding: 0;
    background-color: transparent !important;
    width: 100%;
    border-radius: 8px !important;

    &:focus-visible {
      outline: none;
    }

    & > ul > li > a {
      background-color: transparent !important;
      overflow: hidden;
      border-radius: 8px;
      transition:
        background-color 0.2s,
        color 0.2s;
      padding: 8px;
      height: 41px;
      border: none !important;

      .p-menuitem-text,
      .p-submenu-icon {
        color: white !important;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.05) !important;
      }

      .p-submenu-icon {
        transform: translateX(3px);

        &::before {
          font-size: 16px;
        }
      }
    }

    a:focus,
    a:focus-visible {
      border-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
    }

    .p-submenu-list {
      padding: 0;
      border-radius: 2px;
      overflow: hidden !important;
    }
  }
}
