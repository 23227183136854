.layout-menu {
  .layout-root-menuitem {
    svg[data-testid=DashboardOutlinedIcon] {
      transform: rotate(90deg);
    }
  }
}

.dashboard-card {
  .p-card-body {
    padding: 16px;
    height: 100%;

    .p-card-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

.dashboard-filter {
  @media (max-width: 575px) {
    >* {
      width: 100% !important;
    }
  }
}

// .pie-h-legends {
//   .recharts-wrapper {
//     position: relative;
//     display: flex;
//     gap: 10px;
//     > * {
//       position: relative!important;
//     }
//   }
// }

.recharts-legend-wrapper {
  min-width: 125px;
}

.billed-time-tooltip {
  gap: 10px;
  padding: 10.5px;
  border-radius: 3px;
  font-size: 12px;
  max-width: 163px;
  background: #40464D;

  & .label-container {
    padding: 3px;
    border-radius: 4px;
  }
}

@media (max-width: 720px) {
  .custom-recharts-legend {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    // justify-content:  center;
    font-size: 12px;

    >* {
      width: 40%;
      flex: 1;
    }
  }
}

// .recharts-wrapper {
//   border: 1px solid blue;
// }
// .recharts-wrapper:after {
//   content: "";
//   display: inline-block;
//   width: 10px;
//   height: 10px;
//   background: blue;
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(50%);
//   z-index: 10000000000000;
// }

.dashboard-card.p-card {
  .p-card-body {
    padding: 16px;
  }
}