.file-upload-container {
    position: relative;
    height: 100%;
    min-height: 200px;
    border: dashed var(--surface-500);
    border-radius: 0.5rem;

    &--fixed {
        height: 200px;
        min-height: auto;
    }


    .p-progressbar {
        display: none;
    }


    .file-upload-header {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        display: flex;
        flex-direction: column;
    }



    .p-fileupload.p-fileupload-advanced.p-component {
        height: 100%;

    }

    .p-fileupload-content {
        background-color: rgb(245, 245, 245);
        height: 100%;
        padding: 0;
        border-radius: 6px;
        overflow: hidden;




        // 
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .file-upload-empty {
        width: 100%;
        height: 100%;
        background: transparent;
    }

    .file-upload-instructions {
        text-align: center;
        margin-top: 0.5rem;
    }


    &.staged {
        border: none;


        .file-upload-header {
            right: 1rem;
            height: 100%;
            width: 100%;
            transition: background-color 200ms ease-in-out;


            &:hover {
                background-color: rgba(0, 0, 0, 0.205);

                .file-upload-header__buttons {
                    opacity: 1;
                    transition: opacity 200ms ease-in-out;

                }
            }

            &__buttons {

                display: flex;
                justify-content: flex-end;
                gap: 1rem;
                padding: 1rem;
                opacity: 0;

            }
        }

    }
}

.p-fileupload-files {

    .file-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        .pi {
            font-size: 2rem;
        }
    }
}

.p-menuitem {
    .p-fileupload {
        .p-button {
            color: var(--text-color);
            background: initial;
            border: initial;
            padding: 0;
            font-size: 1rem;
            transition:box-shadow 0.2s;
            border-radius: 6px;

            &:not(button):not(a):not(.p-disabled):hover{
                background: initial;
                color: var(--text-color);
                border-color: transparent;
                box-shadow: none;
            }
            &:not(.p-button-outlined, .p-button-text, .p-button-link, .p-togglebutton) {
                box-shadow: none;
            }

            .p-button-label {
                font-weight: normal;
                text-align: left;
            }
        }
    }
    
}