.tab-menu-content-container {
  // background-color: white;
  background-color: var(--surface-card);
  padding: 1.15rem;
}

.p-tabview-nav li.deletable-project .pi.pi-times::before {
  content: "\e93d"; //trashcan icon
  color: var(--clr-danger);
}

.p-tabview-nav .text-blue-500{
  color: light-dark(var(--blue-500), #00B4FF)!important;
}