.pallets-alert{
    width: 15px;
    height: 15px;
    background: red;
    /* display: inline; */
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;

}

.ordenes-de-venta-cards-columns{
    flex: none !important;
    @media  screen and (min-width:1370px) {
        flex: 1 1 auto !important;
        
    }
    
    
   &__wrapper {
     scrollbar-gutter: stable;
    position: relative;

    &::-webkit-scrollbar {
        width: 10px;
        position: absolute;
        right: 0;
    }

    &::-webkit-scrollbar-track{
            background-color: rgba(211, 211, 211, 0.295);
        }
    &::-webkit-scrollbar-thumb{
            background-color: rgb(216, 216, 216);
            border-radius: 10px;
        }
    
       
    }
    
}

.text-clr-gray-600 {
    color: var(--clr-gray-600);
}
.text-clr-blue-600 {
    color: var(--clr-blue-600);
}
.text-clr-blue-200 {
    color: var(--clr-blue-200)!important;
}

.bg-clr-gray-blue-100 {
    background-color: var(--clr-gray-blue-100);
}
.bg-clr-blue-700 {
    background-color: var(--clr-blue-700);
}
.bg-clr-orange-200 {
    background-color: var(--clr-orange-200);
}
.bg-clr-green-100 {
    background-color: var(--clr-green-100);
}
.bg-clr-red-200 {
    background-color: var(--clr-red-200);
}
.bg-clr-gray-200 {
    background: var(--clr-gray-200);
}

.bg-Laredo {
    background-color: var(--clr-blue-600);
}
.bg-McAllen {
    background-color: var(--clr-yellow-200);
    color: black!important;
}
.bg-Nogales {
    background-color: var(--clr-purple-700);
}

// ------ items de orden de venta ------

// Bordes de fila
.items-table-row-border {
    position: relative;
    border: 3px solid;
    border-left-width: 6px;
}
.items-table-row-border-success {
    @extend .items-table-row-border;
    border-color: var(--clr-green-300)!important;
}
.items-table-row-border-warn {
    @extend .items-table-row-border;
    border-color: var(--clr-yellow-700);
}

// Iconos en esquina del borde de la fila
.items-table-row-icon {
    position: absolute;
    font-size: 15px;
    top: -16px;
    left: -16px;
    z-index: 1;
    padding: 6px;
    border-radius: 100%;
    line-height: normal;
}
.items-table-row-icon-success {
    @extend .items-table-row-icon;
    color: white;
    background-color: var(--clr-green-300);
}
.items-table-row-icon-warn {
    @extend .items-table-row-icon;
    background-color: var(--clr-yellow-700);
}

// Bordes de celda
.items-table-border {
    position: relative;
    border: 3px solid;
}
.items-table-border-primary {
    @extend .items-table-border;
    border-color: var(--clr-blue-600)!important;
}
.items-table-border-success {
    @extend .items-table-border;
    border-color: var(--clr-green-300)!important;
}

// Iconos de borde de celda
.items-table-cell-border-icon {
    position: absolute;
    top: -3px;
    left: -10px;
    z-index: 1;
    padding: 6px;
    border-radius: 100%;
    color: white;
    font-size: 12px;
    line-height: normal;
}
// Iconos de borde de celda
.items-table-border-icon-primary {
    @extend .items-table-cell-border-icon;
    background-color: var(--clr-blue-600);
}
.items-table-border-icon-success {
    @extend .items-table-cell-border-icon;
    background-color: var(--clr-green-300);
}

// ------ Asignar items ------
.asignar-items-card > div.p-card-body {
    padding: 14px;
}

.asignar-items-card-h-full > div.p-card-body, .asignar-items-card-h-full div.p-card-content {
    height: 100%;
}

.asignar-items-item-card {
    border: 1px solid #CED4DA;
    background-color: #F4F6FA;
}

// .card-content-h-full > div.p-card-body {
//     height: 100%;
// }
// .card-content-h-full > div.p-card-body > div.p-card-content {
//     height: 100%;
// }

.panel-border-none div.p-panel-content {
    border: none;
}
.panel-padding-0 div.p-panel-content {
    padding: 0;
}


.administracion-camiones-items-container {
    display: grid;
    grid-template-rows: max-content calc( 100% - var(--filters-height) );
    width: 100%;
    height: 100%;

    --filters-height: 106px;

    @media (max-width: 768px ) {
        --filters-height: 160px;
    }

    &__cards {
        overflow: hidden;
    }
}


.administracion-camiones-items-sin-asignar-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        flex-shrink: 0;
    }

    &__cards {
        flex-grow: 1;
        height: 100%;
    }
}

.administracion-camiones-camion-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__header {
        flex-shrink: 0;
    }

    &__body {
        flex-grow: 1;
        height: 100%;
    }
}

.over-droppable-card div {
    color: #167DD7;
}

.text-959595 {
    color: #959595;
}

.text-212121 {
    color: #212121;
}

// camión
.camion {
    padding: 10px 86px 10px 10px;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column-reverse ;
    height: 52px;
    width: 277px;
    justify-content: center;
    align-items: flex-start;
    background-size: contain;
    background-repeat: no-repeat;
}
.camion-pallet {
    width: 10.1px;
    height: 12.7px;
    margin: 1.5px 1px;
    border: 1px solid #BAC3C3;
}

.manifiesto-items-badge {
    font-size: 10px;
    background-color: #F0CAFE;
    border-color: #7B1EC1;
    color: #7B1EC1;
}
.manifiesto-items-badge-inactive {
    font-size: 10px;
    background: #FCFCFC;
    border-color: #7E7E7E;
    color: #7E7E7E;
}


// historial cambios
.icono-ver-historial {
    opacity: 0;
}
.ver-historial:hover {
    cursor: pointer;

    * {
        color: var(--clr-blue-600)!important;
    }

    .manifiesto-items-badge {
        background-color: var(--clr-blue-200);
        border-color: var(--clr-blue-700);
    }

    .icono-ver-historial {
        opacity: 1;
    }

    span.text-clr-blue-200 {
        @extend .text-clr-blue-200;
    }
}

.ver-historial-border {
    border: 0.5px solid transparent;
}
.ver-historial-border:hover {
    border: 0.5px solid var(--clr-blue-700)!important;
}
.ver-historial-custom-border:hover {
    border: 0.5px solid var(--clr-blue-700)!important;
}

.ver-historial-custom-hover:hover {
    cursor: pointer;

    .ver-historial-custom-hover-text {
        color: var(--clr-blue-600)!important;
    }

    .icono-ver-historial {
        opacity: 1;
    }

    span.text-clr-blue-200 {
        @extend .text-clr-blue-200;
    }
}

// Bordes de estado (Para indicar actualizaciones, eliminaciones, etc)
.border-state {
    position: relative;
    border: 3px solid;
}
.border-state-icon {
    position: absolute;
    top: -12px;
    left: -12px;
    z-index: 1;
    padding: 6px;
    border-radius: 100%;
    color: white;
    font-size: 12px;
    line-height: normal;
}
.border-state-updated {
    @extend .border-state;
    border-color: var(--clr-blue-600);

    >.border-state-icon {
        @extend .border-state-icon;
        background-color: var(--clr-blue-600);
    }
}
.border-state-deleted {
    @extend .border-state;
    border-color: var(--clr-yellow-700);

    >.border-state-icon {
        @extend .border-state-icon;
        background-color: var(--clr-yellow-700);
        color: black;
    }
}
.border-state-created {
    @extend .border-state;
    border-color: var(--clr-green-300);

    >.border-state-icon {
        @extend .border-state-icon;
        background-color: var(--clr-green-300);
    }
}
