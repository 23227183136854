.p-multiselect-trigger-icon,
.p-dropdown-trigger-icon {
  font-size: 7px;
}
// Form labels
.grid.formgrid .grid.formgrid .field:not(:has(.p-inputswitch)) label {
  font-weight: 700 !important;
}

.empty-data-teammember-message,
.empty-data-piechart-message {
  // color: var(--secondary-color-dark);
  * {
    color: light-dark(
      var(--secondary-color-dark),
      var(--text-color)
    ) !important;
  }
  .message {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    font-family: Mulish, sans-serif;
  }
}
