.budget-movements-and-updates-table {
  .p-datatable-table {
    tr[role="row"] {
      min-height: 38px;
    }

    .p-datatable-thead {
      tr th:nth-child(1) {
        width: 31.5px;
      }

      tr th:nth-child(2) {
        width: 114px;
      }

      tr th:nth-child(4) {
        width: 214px;
      }

      tr th:nth-child(5) {
        width: 214px;
      }

      tr th:nth-child(6) {
        width: 214px;
      }
    }

    .p-datatable-tbody {
      tr.budget-movement-disabled {
        td {
          opacity: 0.5;
        }
      }
    }
  }
}

#general-budget-information-card {
  .value-with-label-template:nth-child(3) {
    min-width: 234px;
  }
}

body:has(.client-budget-layout-container) {
  .tendency-chip {
    .p-chip-icon {
      font-size: 7px;
    }
  }
}