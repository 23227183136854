.squared-icon {
  height: 48px;
  border-radius: 10px;  
}

.squared-icon-size {
  &--normal-text{
    width: 48px;
  }
  &--large-text{
    min-width: 48px;
    padding: 0px 6px;
  }
}

.squared-icon-invFisico{
  height: 48px;
  border-radius: 10px;
  border: 2px solid var(--clr-color);
}
.small-badge {
  height: 22px;
  border-radius: 4px;
  // text-align: left;
  color: var(--clr-text);

  // & + .small-badge {
  //   margin-left: .5rem;
  // }

  &.p-badge {
    padding-inline: .21rem;
    line-height: 22px;
  }

}

.badge-produccion-general{
  
    border-radius: 20px !important;
    display: flex; 
}
.squared-icon, .small-badge, .color-invernadero {


  background-color: var(--clr-background);

  &--extra-badge {
    --clr-background: var(--extra-badge-background);
    --clr-text: var(--extra-badge-text);
  }

  &--green {
    --clr-background: var(--clr-green-900);
    --clr-text: var(--clr-green-100);
  }

  &--baby-blue {
    --clr-background: var(--clr-blue-600);
    --clr-text: var(--clr-blue-300);
  }

  &--blue {
    --clr-background: var(--clr-blue-700);
    --clr-text: var(--clr-blue-200);
  }

  &--violet {
    --clr-background: var(--clr-purple-700);
    --clr-text: var(--clr-purple-200);
  }

  &--magenta {
    --clr-background: var(--clr-pink-700);
    --clr-text: var(--clr-pink-200);
  }

  &--red {
    --clr-background: var(--clr-red-700);
    --clr-text: var(--clr-red-200);
  }

  &--orange {
    --clr-background: var(--clr-orange-700);
    --clr-text: var(--clr-orange-200);
  }

  &--gold {
    --clr-background: var(--clr-yellow-800);
    --clr-text: var(--clr-yellow-100);
  }

  &--pastel-light-green {
    --clr-background: var(--clr-green-100);
    --clr-text: var(--clr-green-300);
  }

  &--pastel-dark-green {
    --clr-background: var(--clr-green-100);
    --clr-text: var(--clr-green-900);
  }

  &--pastel-blue {
    --clr-background: var(--clr-blue-200);
    --clr-text: var(--clr-blue-700);
  }

  &--pastel-lavender {
    --clr-background: var(--clr-lavender-100);
    --clr-text: var(--clr-lavender-800);
  }

  &--pastel-violet {
    --clr-background: var(--clr-purple-200);
    --clr-text: var(--clr-purple-700);
  }

  &--pastel-magenta {
    --clr-background: var(--clr-pink-200);
    --clr-text: var(--clr-pink-700);
  }

  &--pastel-carmine {
    --clr-background: var(--clr-pink-100);
    --clr-text: var(--clr-pink-900);
  }

  &--pastel-berry {
    --clr-background: var(--clr-red-100);
    --clr-text: var(--clr-red-900);
  }

  &--pastel-red {
    --clr-background: var(--clr-red-200);
    --clr-text: var(--clr-red-800);
  }

  &--pastel-orange {
    --clr-background: var(--clr-orange-200);
    --clr-text: var(--clr-orange-800);
  }

  &--pastel-brown {
    --clr-background: var(--clr-orange-200);
    --clr-text: var(--clr-orange-900);
  }

  &--cream-yellow {
    --clr-background: var(--clr-yellow-100);
    --clr-text: var(--clr-yellow-900);
  }

  &--pastel-yellow {
    --clr-background: var(--clr-yellow-200);
    --clr-text: var(--clr-yellow-800);
  }

  &--gray-1 {
    --clr-background: var(--clr-gray-900);
    --clr-text: var(--clr-gray-green-100);
  }

  &--gray-2 {
    --clr-background: var(--clr-gray-400);
    --clr-text: var(--clr-gray-red-100);
  }

  &--gray-3 {
    --clr-background: var(--clr-gray-300);
    --clr-text: var(--clr-gray-700);
  }

  &--gray-4 {
    --clr-background: var(--clr-gray-700);
    --clr-text: var(--clr-gray-blue-100);
  }

  &--gray-5 {
    --clr-background: var(--clr-lavender-200);
    --clr-text: var(--clr-violet-900);
  }

  &--htg {
    --clr-background: var(--clr-brand);
    --clr-text: var(--clr-gray-blue-100);
  }


  &__text {
    color: var(--clr-text);
  }
}

.small-badge-invFisico{
  height: 22px;
  border-radius: 4px;
  color: var(--clr-color);
  border: 1px solid var(--clr-color);

  // & + .small-badge {
  //   margin-left: .5rem;
  // }

  &.p-badge {
    padding-inline: .21rem;
  }
}

.squared-icon-invFisico, .small-badge-invFisico, .color-invernadero-fisico{
  background-color: white;
  --clr-color: var(--clr-brand);
  // h1
  &--green {
    --clr-color: var(--clr-green-900);
  }

  // h2
  &--pastel-light-green{
    --clr-color: var(--clr-green-400);
  }

  // I1
  &--gold{
    --clr-color: rgba(97, 79, 0, 1);
  }

  // I2
  &--cream-yellow {
    --clr-color: rgba(168, 145, 39, 1);
  }

  // F1
  &--red{
    --clr-color: var(--clr-red-400);
  }

  // F2
  &--pastel-carmine{
    --clr-color: var(--clr-red-800);
  }

  // A1
  &--pastel-violet {
    --clr-color: var(--clr-purple-700);
  }

  // A2
  &--violet {
    --clr-color: rgba(47, 21, 158, 1);
  }

  // A3
  &--blue {
    --clr-color: var(--clr-blue-700);
  }

  // A4
  &--pastel-berry {
    --clr-color: var(--clr-red-900);
  }

  //T1
  &--orange{
    --clr-color: var(--clr-orange-800);
  }

  //T2
  &--pastel-orange {
    --clr-color: var(--clr-orange-700);
  }

  // T3
  &--magenta {
    --clr-color: var(--clr-violet-400);
  }

  //T4
  &--pastel-magenta{
    --clr-color: var(--clr-pink-700);
  }

  &__text {
    color: var(--clr-color);
  }
}

.rounded-badge{
  height: 22px;
  border-radius: 50px;
  color: var(--clr-text);
  background-color: var(--clr-background);

  // & + .rounded-badge {
  //   margin-left: .5rem;
  // }

  &.p-badge {
    padding-inline: .21rem;
    line-height: 22px;
  }

  &--grape{
    --clr-background: var(--clr-purple-700);
    --clr-text: var(--clr-purple-200);
  }

  &--cherry{
    --clr-background: var(--clr-pink-800);
    --clr-text: var(--clr-red-200);
  }

  &--heirloom{
    --clr-background: var(--clr-orange-700);
    --clr-text: var(--clr-orange-200);
  }

  &--medleys{
    --clr-background: var(--clr-yellow-700);
    --clr-text: var(--clr-yellow-200);
  }

  &--beef{
    --clr-background: var(--clr-red-400);
    --clr-text: var(--clr-red-200);
  }

  &--cocktail{
    --clr-background: var(--clr-green-400);
    --clr-text: var(--clr-green-100);
  }

  &--tov{
    --clr-background: var(--clr-violet-800);
    --clr-text: var(--clr-purple-200);
  }

  &__text {
    color: var(--clr-text);
  }
}

.client-badge{
  height: 22px;
  border: 1px solid var(--clr-border);
  border-radius: 4px;

  --clr-border: var(--clr-brand);

  &--mastronardi{
    --clr-border: var(--clr-orange-700);
  }

  &--nature-fresh-farms{
    --clr-border: var(--clr-nature-fresh);
  }

  &--nature-sweet{
    --clr-border: var(--clr-nature-sweet);
  }

  &--windset-farms{
    --clr-border: var(--clr-red-400);
  }
}