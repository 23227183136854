.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-text {
  --max-lines: 1;

  &.max-lines {
    &-1 {
      --max-lines: 1;
    }
    &-2 {
      --max-lines: 2;
    }
    &-3 {
      --max-lines: 3;
    }
    &-4 {
      --max-lines: 4;
    }
    &-5 {
      --max-lines: 5;
    }
    &-6 {
      --max-lines: 6;
    }
    &-7 {
      --max-lines: 7;
    }
    &-8 {
      --max-lines: 8;
    }
  }

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines, 1); /* number of lines to show */
}

.absolute-center-v {
  top: 50%;
  transform: translateY(-50%);
}

.absolute-center-h {
  left: 50%;
  transform: translateX(-50%);
}

.font-cairo {
  font-family: "Cairo", sans-serif;
}

.number-with-danger-badge {
  width: 4.5rem;
  justify-content: space-between;
}
.layout-main-content {
  overflow: auto;
}
// Check consequences
i.pi {
  line-height: 1;
}

.h-shake {
  animation: h-shake 0.5s;
}

@keyframes h-shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(3px);
  }
  50% {
    transform: translateX(-3px);
  }
  75% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}
