* {
  // scrollbar-gutter: stable;
  // position: relative;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;
    border-radius: 10px;
    background-color: rgba(211, 211, 211, 0.1);
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(211, 211, 211, 0.295);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(216, 216, 216);
    border-radius: 10px;
  }
}

.layout-main-content {
  overflow-y: auto;
  scrollbar-gutter: stable;
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: thin; /* Opciones: auto, thin */
    scrollbar-color: rgb(216, 216, 216) rgba(211, 211, 211, 0.295);
  }
}
