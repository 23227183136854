.exception-page {
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
  .divider {
    width: 7px;
    height: 161px;
    margin: 0 28px;
    background: #00B4FF;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 7px!important;
      margin: 28px auto;
    }
  }
  h1.error-code {
    font-size: 8rem;
    color: light-dark(#24292F, #F0F6FC);
  }
  h2.error-title {
    font-weight: 700;
    font-size: 32px;
  }
  .footer {
    height: 76px;
  }
  .error-container{
    max-width: 650px;
    margin: auto;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}

.layout-wrapper:has(.exception-page) {
  &.layout-sidebar .layout-main:has(.layout-menu-wrapper.layout-sidebar-active){
    margin-left: 0!important;
  }
  .layout-menu-wrapper,
  header,
  .mobile-header-container{
      display: none!important;
  }
  .layout-main {
      margin: 0;
  }
  .layout-main-content{
      margin: 0!important;
      padding: 0!important;
  }
}