#general-report {

  // .p-datatable .p-datatable-wrapper
  // .p-datatable-table .p-datatable-tbody
  // tr[role=row]:first-child td:nth-child(2):not(.p-frozen-column),
  .p-datatable {
    overflow: auto;
  }

  .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr[role="row"].expanded-row-content>td:nth-child(2) {
    min-width: 205px;
  }

  th[role="columnheader"]:has(span[class*="tooltip-"]):hover {
    // background: #CACDD2!important;
    background: light-dark(#cacdd2, var(--secondary-color)) !important;
    cursor: pointer;
  }
}

// #individual-report {
//   .individual-report-table {
//     .p-datatable-tbody tr[role=row] td:nth-child(4) {
//       width: 305px !important;
//       min-width: 305px;
//       max-width: 305px;
//     }
//   }
// }
.client-chip {
  overflow: hidden;
  position: relative;
  z-index: 0;

  .bg-color {
    transition: all 0.2s;
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

#individual-report,
#general-report {
  .custom-datatable-footer {
    position: sticky;
    bottom: -1px;
    z-index: 2;

    td {
      background: light-dark(#f3f7fc, var(--surface-card));
    }
  }

  .ctrl-selectable-badge .client:hover,
  .ctrl-selectable-badge .project:hover {
    opacity: 0.6;
  }

  .ctrl-selectable:hover {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }

  .client-project-chip,
  .project-chip,
  .client-chip {
    &.ctrl-selectable-badge:hover {
      cursor: pointer;

      .bg-color {
        filter: brightness(0.6);
      }
    }
  }

  .ctrl-selectable-dropdown:hover {
    box-shadow: 0px 0px 7px 2px rgba(0, 123, 255, 0.3);
  }
}

#general-report,
#individual-report,
#occupation-report {
  background: var(--surface-card);
  border-radius: var(--border-radius);

  .p-input-icon-left:has(.p-calendar) input {
    width: 225px;
  }
}

.summary-charts-container {
  @media screen and (max-width: 1300px) {
    flex-direction: column;

    .doughnut-chart {
      display: flex;
      flex-direction: column;

      .mt-4.flex {
        align-self: center;
      }
    }
  }
}

.day-footer-total {
  width: 106px;
}

.day-footer-title {
  margin-right: 17px;
}

body:has(.hours-by-team-and-team-member-report) .p-tooltip {
  max-width: 250px;
}

body:has(#individual-report) .p-tooltip {
  max-width: 262px;
}

.p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav {
  padding: 21px 0px 0 0px;
  margin: 0 21px;
}

li.p-tabview-ink-bar {
  margin-left: 21px;
}

// Doughnut chart skeleton
.doughnut-skeleton-container {
  position: relative;
  width: 219px;
  height: 219px;
}

.doughnut-skeleton {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.doughnut-section {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
}

.doughnut-section.section-2 {
  clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%);
  background: rgba(0, 0, 0, 0.1);
  transform: rotate(180deg);
  animation: rotate-section-2 2.75s infinite linear;
}

.doughnut-hole {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 153px;
  height: 153px;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

@keyframes rotate-section-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-section-2 {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(540deg);
  }
}

#general-report .p-input-icon-left:hast(.p-calendar) {
  width: 300px;
}

#general-report {
  .p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead {
    display: block;
  }

  .p-datatable-scrollable.p-datatable-grouped-header .p-datatable-thead>tr>th {
    display: table-cell;
    vertical-align: inherit;
  }
}

#general-report .week-hours-report-data-table .team-members-count-badge {
  background: var(--primary-color);
  color: var(--primary-color-text);
  border-radius: 16px;
  display: inline-flex;
  width: 32px;
  max-width: 32px;
  height: 20px;
  padding: 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  text-align: center;
  font-size: 12px;
  font-weight: 700;
}

// TODO: to remove
.week-hours-report-data-table-modal {
  max-width: 680px !important;

  .header-wrapper {
    font-size: 24px;

    img {
      width: 32px;
      height: 32px;
      padding: 2px;
    }
  }

  .team-member {
    margin-top: 11px;
    margin-bottom: 32px;
  }

  table {
    width: 100%;

    td:nth-child(1) {
      max-width: 90px;
      width: 90px;

      .client-project-chip {
        max-width: max-content;
      }
    }
  }

  tbody tr {
    td:nth-child(2) {
      padding: 0 24px;
      text-align: left;
    }

    td:nth-child(3) {
      width: 134px;
    }

    height: 40px;
    position: relative;

    &:not(:first-child) {

      td>p,
      td>div {
        padding-top: 10px;
      }
    }

    td>p,
    td>div {
      padding-bottom: 4px;
    }

    &::after {
      content: "";
      display: block;
      height: 1px;
      background: #dce1e8;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  tfoot tr {
    height: 34px;

    td>p,
    td>div {
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}

.consumo-semanal-report-hours-details-modal,
.rendimiento-por-proyectos-report-hours-details-modal {
  max-width: 680px;

  .p-datatable .p-datatable-wrapper .p-datatable-table {
    .p-datatable-thead tr[role="row"] th {
      padding: 8px;
    }

    .p-datatable-tbody tr[role="row"] td {
      padding: 8px;
    }

    .p-datatable-tfoot tr[role="row"] td {
      padding: 8px;
    }
  }
}

#individual-report {
  .doughnut-chart .custom-recharts-tooltip {
    padding: 10.5px;
    border-radius: 3px;
    background: #535c69;
  }
}

.occupation-report-controls {
  margin-bottom: 17px;

  .p-selectbutton {
    * {
      font-weight: 400;
    }
  }
}

.custom-hours-type-tooltip {
  min-width: 180px;
  border-radius: 3px;
  background: #40464d;
  padding: 10.5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;

  * {
    color: #fff;
    font-family: Mulish, Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
  }

  h2 {
    font-size: 14px;
    font-weight: 700;
  }

  .badges-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  hr {
    margin: 10px 0;
    border-top: 1px solid #6e7b8d;
  }
}

.hours-type-badge {
  font-size: 12px;
  padding: 3px;
  border-radius: 4px;
  color: var(--surface-b);
  font-weight: 700;
  min-width: 71px;
  text-align: center;
}

.custom-legend-badge-container {
  .lateral-line {
    width: 13px;
    border-top: 2px solid;

    &.border-dash {
      border-top-style: dashed;
    }
  }
}

#general-report .team-member-picker {
  max-width: 260px;
  width: 100%;
}

div:has(#occupation-report) {
  .recharts-surface {
    overflow: visible;
  }

  &~.p-multiselect-panel {

    .p-multiselect-header,
    .p-multiselect-items-wrapper {
      margin: 0 16px;
    }

    .p-multiselect-header {
      margin-top: 16px;
      box-shadow: 0px -2px 12px 0px light-dark(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1));
    }

    .p-multiselect-items-wrapper {
      box-shadow: 0px -2px 12px 0px light-dark(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1));
      border-radius: 0 0 6px 6px;
    }
  }

  .layout-main-content {
    display: flex;
    flex-direction: column;
  }
}

#occupation-report {
  .p-tabview {
    height: 100%;
    display: flex;
    flex-direction: column;

    .p-tabview-panels {
      flex: 1;

      >div {
        height: 100%;
      }

      .p-card.h-full {

        .p-card-body,
        .p-card-content {
          height: 100%;
        }
      }
    }
  }

  &:has(.p-tabview > .p-tabview-panels > #pr_id_2_content_1) {
    flex: 1 1 !important;
  }
}

.layout-main-content {
  display: flex;
  flex-direction: column;

  #general-report,
  #individual-report,
  #clients-table,
  #occupation-report {
    display: flex;
    overflow: auto;

    .p-card-body {
      width: 100%;
    }

    .p-tabview {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      .p-tabview-panels {
        display: flex;
        overflow: auto;

        .p-tabview-panel {
          display: flex;
          width: 100%;
          flex-direction: column;
        }
      }
    }
  }

  #occupation-report {
    .p-datatable {
      max-height: 100%;
      overflow: hidden;

      .p-datatable-wrapper {
        max-height: 100%;
        overflow: auto;
        position: relative;
      }
    }
  }
}

body:has(#individual-report) {
  .individual-registered-tasks-setting-btn {
    width: 45px;
    height: 45px;
    border-radius: 50% !important;

    .pi.pi-cog {
      font-size: 21px;
    }
  }

  .individual-registered-tasks-setting-overlay {
    .p-overlaypanel-content {
      padding: 0;
      border-radius: 6px;
      overflow: hidden;
      width: 205px;

      .panel-option {
        padding: 10.5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 7px;

        &:hover {
          background: var(--surface-bg);
        }

        .pi {
          font-size: 17.5px;
          padding: 3.25px;
        }
      }
    }
  }

  .ant-picker-dropdown {
    z-index: 10000;
  }

  .create-task-admin {
    .ant-picker-input {
      input {
        background: unset;
        text-transform: unset;
      }
    }
  }
}

#general-report .consumo-semanal .p-datatable-wrapper .p-datatable-tfoot {
  bottom: -1px;

  td {
    .custom-t-footer-column {
      width: 100%;

      .row {
        position: relative;
        padding: 14px 7px;

        &:first-child::before {
          content: "";
          display: block;
          height: 1px;
          background: light-dark(#dee2e6, var(--secondary-color-dark));
          position: absolute;
          top: -1px;
          left: 0;
          right: 0;
        }

        &:first-child::after {
          content: "";
          display: block;
          height: 1px;
          background: light-dark(#dee2e6, var(--secondary-color-dark));
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }

        .cell {
          width: 100%;
        }
      }
    }
  }
}

// .tareas-por-cliente {
//   .p-datatable-tfoot tr {
//     display: flex !important;
//   }
// }

// So that thead has the same height always as it would get bigger on smaller screens
#individual-report {
  .p-datatable-thead th {
    min-height: 51px !important;
  }
}

.consumo-semanal-report-hours-details-modal,
.rendimiento-por-proyectos-report-hours-details-modal {
  .p-rowgroup-header {
    td {
      padding-right: 0px !important;

      .p-rowgroup-header-name {
        width: 100%;
      }
    }
  }
}

.rendimiento-por-proyectos-report-hours-details-modal {
  tr:not(.p-rowgroup-header) {
    td {
      background: var(--surface-bg) !important;
    }
  }
}

#individual-report,
#general-report .tareas-por-cliente {
  .p-datatable-thead tr th {
    height: 51px;
  }

  .p-rowgroup-header-name {
    width: 100%;
  }

  .p-virtualscroller .p-datatable-table {
    height: fit-content !important;
  }

  tbody.p-datatable-tbody.p-virtualscroller-content {
    top: 51px !important;
    transition-delay: 0.2s;
    min-height: unset !important;
    max-width: 100%;

    tr[role="row"] {
      // max-height: 37px !important;
      max-height: unset !important;
      height: unset !important;

      &.p-datatable-emptymessage {
        max-height: unset !important;
      }
    }
  }

  tr.p-rowgroup-header {
    top: unset !important;
  }

  tr.p-rowgroup-footer:has(.hide) {
    display: none;
  }

  .p-virtualscroller {
    height: calc(100vh - 231px) !important;

    @media screen and (max-width: 1250px) {
      height: calc(100vh - 241px) !important;
    }

    max-height: fit-content;

    @-moz-document url-prefix() {
      height: min(calc(100vh - 231px), 100%) !important;
      max-height: calc(100vh - 231px);
      scrollbar-gutter: stable;
    }

    &:has(.p-datatable-emptymessage) {
      // min-height: 261px;
      min-height: fit-content;
      overflow-y: hidden;
    }

    contain: unset !important;
  }

  .p-datatable-wrapper {
    height: 100% !important;
  }

  .duplicate {
    display: none !important;
  }

  .p-rowgroup-footer:has(.duplicate),
  .p-rowgroup-header:has(.duplicate) {
    display: none !important;
  }

  .p-datatable-scrollable .p-virtualscroller>.p-datatable-table:not(:has(.p-datatable-emptymessage)) {
    min-height: 100%;
  }

  tbody.p-datatable-tbody.p-virtualscroller-content.p-virtualscroller-loading {
    position: sticky;
    top: 51px !important;

    tr {
      // max-height: 37px !important;
      max-height: unset !important;

      td {

        // padding: 0!important;
        &:first-child {
          flex: 0;
        }
      }
    }

    .p-rowgroup-header,
    .p-rowgroup-footer {
      min-height: 0 !important;

      td {
        padding: 0 !important;
      }
    }
  }

  .custom-datatable-footer {
    min-width: 1102px;
    width: 100%;
    outline: unset;
  }

  tr:has(.column-skeleton) {
    td:last-child {
      max-width: 30px;
    }
  }

  .p-virtualscroller-content:has(.p-datatable-emptymessage) {
    position: unset !important;
  }

  .p-datatable-emptymessage>td {
    flex: 1 !important;
  }

  .p-datatable-striped .p-datatable-wrapper {
    overflow: hidden;
  }
}

.layout-main-content {
  #individual-report:has(.individual-report-summary) {
    .p-tabview {
      .p-tabview-panels {
        padding-bottom: 0;

        .p-tabview-panel {
          margin-bottom: 1.25rem;
          height: fit-content;
        }
      }
    }
  }
}

.sandbox {
  .p-rowgroup-header {
    top: unset !important;
  }
}

.custom-table {
  display: table;
}

.tr {
  display: table-row;
}

.td {
  display: table-cell;
}

#general-report .tareas-por-cliente {
  .p-virtualscroller {
    height: calc(100vh - 285px) !important;
    max-height: fit-content !important;

    @media screen and (max-width: 1250px) {
      height: calc(100vh - 305px) !important;
    }

    @-moz-document url-prefix() {
      height: min(calc(100vh - 285px), 100%) !important;
      max-height: calc(100vh - 285px);
    }

    .p-datatable-table {
      min-height: unset;
    }

    &:has(.p-datatable-emptymessage) {
      min-height: 236px;
    }
  }

  .p-datatable-scrollable .p-virtualscroller-content.p-virtualscroller-loading .p-datatable-emptymessage {
    tr {
      td {
        &:first-child {
          flex: 1 !important;
        }
      }
    }
  }
}

#general-report .rendimiento-por-proyectos {
  .p-frozen-column {
    z-index: 1;
  }

  .p-datatable-thead {
    z-index: 2;
  }
}

.p-virtualscroller {
  .p-rowgroup-header {
    .p-datatable-rowgroup-header * {
      background: var(--surface-highlight);
    }

    &:hover {
      .p-datatable-rowgroup-header * {
        background: light-dark(#cdecfe, #0a5587) !important;
      }
    }
  }
}


// Fixing sticky position inconsistency and border-top
#general-report {
  .consumo-semanal {
    .p-datatable-tbody tr:last-child td {
      border-bottom: 0px;
    }

    .p-datatable-tfoot {
      tr:first-child td {
        border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
      }
    }

  }

  .rendimiento-por-proyectos {
    tfoot {
      bottom: -1px;
      border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
    }

    .p-datatable-tbody tr:last-child td {
      border-bottom: 1px solid transparent;
    }
  }

  .consumos-por-equipo {
    .p-datatable-tbody tr:last-child td {
      border-bottom: 1px solid transparent;
    }

    .p-datatable-tfoot {
      bottom: -1px;

      tr:first-child td {
        border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
      }
    }
  }

  .consumo-por-integrante {
    .p-datatable-tbody tr:last-child td {
      border-bottom: 1px solid transparent;
    }


    .p-datatable-tfoot {
      bottom: -1px;

      tr:first-child td {
        border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
      }
    }
  }

  .tareas-por-cliente {
    .p-datatable-tbody tr:last-child td {
      border-bottom: 1px solid transparent;
    }


    .p-datatable-tfoot {
      bottom: -1px;

      tr:first-child td {
        border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
      }
    }
  }
}

.consumo-semanal-report-hours-details-modal {
  .p-datatable-tbody tr:nth-last-child(2) {
    td {
      border-bottom: 0px;
    }
  }

  .p-datatable-tfoot {
    tr:first-child td {
      border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
    }
  }
}


.rendimiento-por-proyectos-report-hours-details-modal {
  .p-datatable-tbody tr:nth-last-child(2) {
    td {
      border-bottom: 0px;
    }
  }

  .p-datatable-tfoot {
    tr:first-child td {
      border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
    }
  }
}

.tareas-por-cliente {
  .p-datatable-tbody tr:nth-last-child(2) {
    td {
      border-bottom: 0px;
    }
  }

  .custom-datatable-footer {
    border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
  }
}