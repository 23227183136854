// Input time
.inplace-time .p-inplace-content-close {
  display: none;
}

.input-time input.p-inputtext.p-component.p-filled.p-inputnumber-input{
  width: 100%;
}

.input-time {
  span.p-inputnumber-button-group {
    border-radius: 0px 6px 6px 0px;
    &, button {
      background: #2896D8;
      border-color: #2896D8;
    }
  }
}

.input-time input.p-inputtext::selection {
  background-color: transparent;
}