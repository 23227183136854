// @media screen and (min-width: 1000px) {
//     .table-fixed-columns {
//         tr {
//             display: flex;

//             &>* {
//                 flex-basis: 100%;
//                 min-width: 0;
//             }
//         }
//     }
// }
// .icon-custom-svg-badge-tooltip{
//     height: 12px;
//     width:  12px;
// }
// .cell-unit {
//     font-size: .75rem;
//     color: #757575;
// }
// .p-datatable {

//     .p-filter-column {
//         min-width: 0;
//         .p-inputtext{
//             text-overflow: ellipsis;
//         }
//     }

//     .tabular-numbers {
//         font-variant-numeric: tabular-nums;
//     }
//     .p-datatable-thead {
        
//         th {
//             background: #f8f9fa;
//         }
//     }
// }
// .empty-message-center{
//     width: 100%;
//     display: flex;
//     justify-content: center;
// }

// // Anchos equivalentes para todas las columnas cuando no son virtual scroll

// .same-width-columns:not(.datatable-virtual-scroller) {
    
//     &.p-datatable-scrollable {

//         .p-datatable-wrapper {
            
//             /* @media screen and (min-height:300px) {
                
//                 min-height: 250px;
//             } */

//             .p-datatable-thead {
//                 tr {
//                     > *{
//                         flex-basis: 100%;
//                     }
//                 }
                
//             }
//         }
//     }

//     .p-datatable-table {
//         width: 100%;
//         .p-inputtext {
//             width: 100%;
//         }
//         .p-inputnumber {
//             width: 100%;
//         }
//         .p-dropdown {
//             .p-dropdown-label {
//                 width: 1cqw;
//             }
//         }
//         .p-multiselect {
//             .p-multiselect-label-container {
//                 width: 1cqw;
//             }
//         }
//         .field {
//             margin-bottom: 0;
//         }
//     }
    
// }

// .maxwith-columna-especial {
//     max-width: 70px;
// }

// // Responsive table height to siblings

// .layout-wrapper{
//     .layout-main{
//         justify-content: flex-start;
//     }

// }
// .layout-main-content{
//     max-height: 100vh;
// }
// .main-with-table{
//     display: grid;
//     grid-template-rows: repeat(var(--main-with-table-elements), max-content) minmax(auto, 100%);
//     overflow: hidden;
//     height: 100%;

//     &.main-with-table--1-element{
//         --main-with-table-elements: 1;
//     }

//     &.main-with-table--2-element{
//         --main-with-table-elements: 2;
//     }
//     &.main-with-table--3-element{
//         --main-with-table-elements: 3;
//     }

//     .p-datatable {
//         height: 100%;
//         overflow: hidden;
//         .p-datatable-wrapper{
//             height: 100%;
//             .p-virtualscroller {
//                 height: 100% !important;
//             }
//         }

//         .p-datatable-footer{
//             position: sticky;
//             bottom: 0;
//         }
//     }
// }
// .remove-min-heigth .p-datatable-tbody{
//     min-height: 0;
// }



// .badge-count-tooltip {
//     border-radius: 100%;
//     background-color: var(--clr-gray-500);
//     color: var(--gray-200);
// }

// .badge-icon-formula {
//     padding: 1px;
//     width: 12px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border: 1px solid var(--gray-500);
//     background-color: #fff;
//     color: var(--gray-500);
//     margin-left: 0.5rem;
// }
// .badge-icon-formula svg{
//     padding: 0;
//     margin: 0;
//     width: 12px;
//     height: 12px;
// }

// .produccion-sin-bono{
//     td{

//         background-color: var(--clr-red-200) !important;
//         &:not(.p-frozen-column){
//             background-color: var(--clr-red-100) !important;
//         }
//     }
// }
// .produccion-novato{
//     td{

//         background-color: var(--clr-green-200) !important;
//         &:not(.p-frozen-column){
//             background-color: var(--clr-green-100) !important;
//         }
//     }
// }

// .remove-table-header .p-datatable-thead{
//     display: none;
// }

// .p-rowgroup-header-name {
//     width: 100%;
// }

// .p-datatable.p-datatable-scrollable-custom {
//     height: 100%;

//     & > .p-datatable-wrapper {
//         height: 100%;
//         overflow: auto;

//         & > .p-datatable-table > .p-datatable-thead {
//             position: sticky;
//             top: 0;
//             z-index: 1;
//         }
//         & > .p-datatable-table > .p-datatable-tfoot {
//             position: sticky;
//             bottom: 0;
//             z-index: 1;
//         }
//     }
// }



// .custom-tree-table tr.expanded-row-content {
//     background-color: #F5F5F5;
// }

// @media (max-width: 960px) {
//     .responsive-table-column-width .p-datatable-tbody td {
//         width: 100%!important;
//         max-width: 100%!important;
//         gap: 1rem;
//     }
//     .responsive-table-flex-column-align .p-datatable-tbody td .flex-column {
//         align-items: end!important;
//     }
// }

// .datatable-wrapper-h-90 div.p-datatable-wrapper {
//     height: 90%!important;
// }

// // .table-with-names {
// //     tr{
// //             height : 80px !important;
// //             td{
// //                 height: auto !important;
// //             }
    
// //     .p-datatable-tbody{
// //         top: 70px !important;
// //     }
// // }
// // }


.p-datatable {
    .p-datatable-wrapper {
        .p-datatable-table{
            .pi:not(.p-checkbox-icon) {
                color: var(--text-color);
            }
            .p-datatable-thead {
                tr[role="row"] {
                    th[role="columnheader"] {
                        background: var(--surface-bg);
                        color: var(--text-color);
                        padding: 7px;
                        .p-column-filter {
                            .p-column-filter-menu-button {
                                text-align: center;
                            }
                            .p-column-filter-menu-button-active {
                                .pi-filter-icon::before{
                                    content: "\e9e0"; //filter-fill icon
                                }
                            }
                        }
                    }
                }
            }
            .p-datatable-tbody{
                tr[role="row"] {
                    &.p-datatable-row-expansion {
                        display: none;
                    }
                    &.expanded-row-content > td {
                        background: var(--surface-bg);
                    }
                    td {
                        // padding: 7px 7px 7px 0px;
                        padding: 7px 7px 7px 7px;
                        background: var(--surface-highlight);
                    }
                }
            }
            .p-datatable-tfoot{
                tr[role="row"] {
                    td {
                        padding: 7px;
                        background: var(--surface-bg);
                        color: var(--text-color);
                    }
                }
            }
        }
    }
}

.power-hovered-over:hover {
    color: orange;
    text-decoration: underline;
    cursor: pointer;
}

.p-column-filter-overlay {
    min-width: 250px;
    .p-column-filter-constraint,
    .p-column-filter-buttonbar {
        padding: 21px!important;
    }
    .p-column-filter-buttonbar{
        padding-top: 0!important;
        button.p-button.p-component.p-button-sm {
            flex: 1;
            &:first-child { // clean btn
                border: unset;
                color: light-dark(var(--secondary-color), #fff);
            }
            &:last-child { // apply btn
                border-radius: 28px;
                border: 1px solid var(--primary-color-highlight);
                background: var(--primary-color-highlight);
            }
        }
    }
}

.p-badge.report-badge {
    border-radius: 6px;
    width: 17.5px;
    height: 17.5px;
    display: flex;
    padding: 3.5px;
    align-items: center;
    i {
        font-size: 7.5px;
        line-height: 9px;
        font-weight: 400;
        margin: 0px;
    }
}

span.p-row-toggler-icon.pi.pi-chevron-right {
    transform: rotate(90deg);
}
span.p-row-toggler-icon.pi.pi-chevron-down {
    transform: rotate(180deg);
}

.p-treetable {
    .p-treetable-wrapper {
            .pi {
                color: var(--text-color);
            }
            .p-treetable-thead {
                tr {
                    th {
                        background: var(--surface-bg);
                        color: var(--text-color);
                        .p-column-filter {
                            .p-column-filter-menu-button {
                                text-align: center;
                            }
                            .p-column-filter-menu-button-active {
                                .pi-filter-icon::before{
                                    content: "\e9e0"; //filter-fill icon
                                }
                            }
                        }
                    }
                }
            }
            .p-treetable-tbody{
                tr {
                    & .expanded-row-content {
                        td {
                            background: var(--surface-bg);
                        }
                    }
                    td {
                        background: var(--surface-highlight);
                        &:has(button.p-treetable-toggler) {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
            }
            .p-treetable-tfoot{
                tr {
                    td {
                        background: var(--surface-bg);
                        color: var(--text-color);
                    }
                }
            }
        
    }
}

.custom-tree-table tr:not(.p-datatable-row-expansion)  td{
    padding-left: 7px!important;
    padding: 7px !important;
}

.custom-tree-table tr th[role=columnheader] {
    padding: 10px !important;
}

.p-.p-datatable-thead th {
    border: unset!important;
    border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark))!important;
    border-bottom: 1px solid light-dark(#dee2e6, var(--secondary-color-dark))!important;
    // box-shadow: inset 0 1px 0 light-dark(#dee2e6, var(--secondary-color-dark)), inset 0 -1px 0 light-dark(#dee2e6, var(--secondary-color-dark))!important;
}

.p-datatable-scrollable .p-datatable-thead th {
    border: unset!important;
    box-shadow: inset 0 1px 0 light-dark(#dee2e6, var(--secondary-color-dark)), inset 0 -1px 0 light-dark(#dee2e6, var(--secondary-color-dark))!important;
}

.p-column-header-content {
    justify-content: space-between;
    display: flex;
    width: 100%;
}

// .custom-tree-table.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr[role=row].expanded-row-content > td:nth-child(2),
// .custom-tree-table.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr[role=row] > td:nth-child(2)
// {
//     min-width: 185px!important; //Check?
//     max-width: 185px;
//     width: 185px;
// }

.p-datatable-emptymessage td {
    justify-content: center;
}
