.p-toast {
  font-family: var(--font-family);
  min-width: fit-content;
  .p-toast-message {
    border: unset!important;
    border-radius: 6px;
    overflow: hidden;
    opacity: 0.9;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.03), 0px 0px 2px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    &-text {
      color: var(--text-color);
      .p-toast-summary {
        font-size: 18px;
        font-family: Cairo, sans-serif;
        font-weight: 600!important;
      }
      .p-toast-detail {
        font-size: 14px;
        font-family: Mulish, sans-serif;
      }
    }
    &-content {
      // align-items: center;
      background: var(--surface-card);
      max-width: 500px!important;
    }
    &-success .p-toast-message-icon {
      margin: auto 0;
      display: block;
      font-size: 36px;
      &:before {
        content: "\e90a";
        color: #2896D8;
      }
    }
  }
  .p-toast-icon-close {
    color: var(--text-color)!important;
  }
}

.p-toast .p-toast-message-icon {
  margin: auto 0;
  display: block;
  font-size: 36px;
}