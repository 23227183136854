.range-date-calendar {
    &__select {

        width: 100%;
        max-width: 155px;
        min-width: 135px;

        .ant-select-selector {
            box-shadow: 0px 0px 7px 2px rgba(0, 191, 255, 0);

        }

        &.ctrl-selectable-range-selector .ant-select-selector:hover {
            box-shadow: 0px 0px 7px 2px rgba(0, 191, 255, 0.3);
        }
    }

    &__date-picker {
        width: 100%;
        max-width: 225px;
        min-width: 225px;
    }

    &__range-picker {
        width: fit-content;
        min-width: 225px;
    }
}

.ant-picker-datetime-panel-container {

    .ant-picker-time-panel * {
        color: light-dark(var(--text-color), white) !important;
    }

    .ant-picker-time-panel-cell-selected {
        .ant-picker-time-panel-cell-inner {
            background: transparent !important;
            border: 1px solid #00b4ff;
        }
    }

    .ant-picker-time-panel-cell-disabled {
        opacity: 0.25;
    }
}

.ant-picker-ok button:not(.ant-btn-variant-solid:disabled) span {
    color: white !important;
}