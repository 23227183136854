.p-selectable-row.datatable-row-disabled {
    background: #f4f4f4 !important;
    color: #7c7c7c !important;
    
    &--disabled{
        pointer-events: none;
    }
}


//cualquier calendar tendrá estos estilos
#root.weekpicker~.p-datepicker .p-datepicker-calendar {

    thead,
    tbody {
        tr {

            td,
            th {

                //estilo base de today
                td.p-datepicker-today>span {
                    // background: #ced4da;
                    background: light-dark(#ced4da, #e9ecef);
                    color: #495057;
                    border-color: transparent;
                }

                //un poco de aire entre los días
                span {
                    width: 2.3rem;
                    height: 2.3rem;
                    margin: 0.1rem;
                }
            }
        }
    }
}



//cualquier weekpicker tendrá estos estilos
#root~.p-datepicker .p-datepicker-calendar:has(.p-datepicker-weekheader) {

    thead {
        tr {

            // resaltar el título de Sem
            th.p-datepicker-weekheader.p-disabled {
                padding-right: 8px;
                font-size: 14px;
                font-weight: bold;
                opacity: 1;
            }
        }
    }

    tbody {
        tr {

            //resaltar el que es un weekpicker, no un date picker
            td.p-datepicker-weeknumber {
                opacity: 1;
                padding-right: 8px;

                span {
                    opacity: 1;
                    background-color: transparent;
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
    }

}

//estilos solo para el weekpicker con clase .weekpicker
#root.weekpicker~.p-datepicker .p-datepicker-calendar:has(.p-datepicker-weekheader) {

    tbody {
        tr {
            td {

                //hack para poner circulo en semana seleccionada idea a ajustar y probar
                span.p-highlight {
                    //cambiar estilos de día lunes seleccionado a día cualquiera
                    background: rgba(68, 72, 109, 0.07);
                    color: #495057;
                    overflow: visible;

                    //hackear círculo alreddor de semana con un pseudoelemento
                    &:before {
                        content: "";
                        width: 2.2rem;
                        height: 2.15rem;
                        border: 1px solid rgb(17, 107, 244); //cambiar a color final;
                        position: absolute;
                        left: -51px;
                        border-radius: 50%;
                    }
                }
            }
        }


        tr:hover {
            td {

                span.p-highlight {

                    //quitar círculo alreddor de semana con un pseudoelemento al hover
                    &:before {
                        content: none;

                    }
                }
            }

            //color de los días al hacer hover IMPORTANTE definir estos colores finales
            td span {
                background-color: #e9ecef;
                color: light-dark(var(--text-color), var(--text-color-secondary));
                &:hover {
                    opacity: 0.6;
                }
            }

            //para mantener estilo del día de hoy sin afectar aún si se hace hover
            td.p-datepicker-today>span {
                background: #ced4da;
                color: #495057;
                border-color: transparent;
            }

            //color del número de la semana al hacer hover, podría cambiarse a otro estilo 
            td.p-datepicker-weeknumber {
                span {
                    opacity: 1;
                    background-color: transparent;
                    border: 1px solid #606468; //cambiar a color final;
                    border-radius: 50%;
                    //color: rgb(17, 107, 244); //cambiar a color final
                }
            }

        }
    }
}


.tooltip-card-data {
    text-align: end;
}
