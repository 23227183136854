.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    position: relative;
}

.Avatar {
    --color-avatar-background: var(--primary-color-200);

    &--lazy {
        cursor: wait;
        border-radius: 50%;
        @extend .animated-background;

        .Avatar__body {
            background-color: initial;
        }
    }

    &.bg-color {
        &-1 {--color-avatar-background: #4D8834;}
        &-2 {--color-avatar-background: #66988B;}
        &-3 {--color-avatar-background: #93B7AE;}
        &-4 {--color-avatar-background: #9AC987;}
        &-5 {--color-avatar-background: #C48966;}
        &-6 {--color-avatar-background: #DB93A0;}
        &-7 {--color-avatar-background: #EABA9D;}
        &-8 {--color-avatar-background: #D9B020;}
    }

    &__body {
        border-radius: 50%;
        background-color: var(--color-avatar-background);
        width: 44px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        &__icon {
            text-align: center;
            vertical-align: middle;
            font-weight: 700;

            &--light {
                color: white;
            }

            &--dark {
                color: black;
            }
        }
    }
}

.badge-sitio-empaque {
  border-radius: 4px;
  padding: 3px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  border: 1px solid;
  height: fit-content;
  min-width: 50px;
  text-align: center;
  max-width: max-content;

  &[data-nombre-sitio="sitio a"] {
    border-color: var(--Sitio-A, #DB000E);
    color: var(--Sitio-A, #DB000E);
  }

  &[data-nombre-sitio="sitio t"] {
    border-color: var(--Sitio-T, #44A026);
    color: var(--Sitio-T, #44A026);
  }

  &[data-nombre-sitio="sitio o"] {
    border-color: var(--orange-clr-orange-700, #E47E20);
    color: var(--orange-clr-orange-700, #E47E20);
  }

  &[data-nombre-sitio="empaque agropark"], &[data-nombre-sitio="agropark"] {
    border-color: var(--Agropark, #003E66);
    color: var(--Agropark, #003E66);
  }
}

.badge-estatus-requisicion-sistemas {
  border-radius: 4px;
  padding: 3px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  border: 1px solid;
  height: fit-content;
  min-width: 50px;
  text-align: center;
  max-width: max-content;

  &[data-nombre-estatus="pendiente"] {
    border-color: #000;
    color: #000;
    background: var(--white, #FFF);
  }
  
  &[data-nombre-estatus="en proceso"] {
    border-color: var(--green-1-bg, #32B20E);
    background: var(--green-clr-green-200, #E5FCD3);
    color: var(--green-clr-green-400, #32B20E);
  }


  &[data-nombre-estatus="finalizado"] {
    border-color: var(--blue-1-bg, #167DD7);
    background: var(--blue-1-txt, #CDECFE);
    color: var(--blue-1-bg, #167DD7);
  }
}

