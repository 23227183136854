#pending-tasks-content {
  height: calc(100vh - 115px);

  .p-card-body {
    padding: 21px;
  }

  .p-tabview {
    .p-tabview-nav {
      border-bottom: 2px solid light-dark(var(--surface-d), var(--secondary-color-dark));
      padding-top: 2px;

      &,
      .p-tabview-ink-bar {
        margin: 0;
      }

      .p-tabview-nav-link {
        padding: 10px 21px;
        min-height: 52px;
      }

      &,
      ul,
      li:not(.p-tabview-ink-bar),
      a {
        background: transparent !important;
      }

      .p-tabview-title {
        color: var(--text-color);
      }
    }
  }

  .p-tabview-panels {
    background: transparent;
    padding: 21px 0;
  }

  .pending-task-creation-wrapper,
  .pending-task-wrapper {
    .p-card-body {
      min-height: 108px;
      display: flex;
      align-items: center;

      .p-card-content {
        width: 100%;
      }
    }


    .p-chip {
      height: 25.5px;
      // cursor: pointer;
    }

    button.p-button-success {
      width: 38px;
      height: 38px;
    }

    .input-time {
      .p-inputnumber {

        >input,
        >span {
          height: 42px;
        }
      }
    }
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: unset;
  }

  .pending-task-data {
    min-width: 510px;

    .pending-task-chips {
      height: 28px;
    }
  }
}

.input-large-cairo-placeholder {

  &,
  &::placeholder {
    color: light-dark(#535c69, var(--surface-highlight));
    font-family: Cairo !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.pending-task-creation-wrapper {
  .p-chip {
    background: var(--surface-bg);
  }
}

.pending-task-wrapper {
  padding: 12px 0;

  .p-chip {
    // background: var(--surface-highlight);
    background: light-dark(var(--surface-highlight), var(--surface-bg));

    &.p-chip-highlight {
      background: light-dark(var(--surface-bg), var(--surface-highlight));
    }
  }

  .pending-task-creation-name {
    input {

      &,
      &::placeholder {
        font-family: Mulish !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      color: var(--text-color) !important;

      &:disabled {
        opacity: 1;
        cursor: text !important;
        pointer-events: unset;
      }
    }
  }
}

.pending-task-creation-wrapper,
.pending-task-wrapper {
  .p-chip {
    // margin: 0 1px;
    color: var(--text-color);
    fill: var(--text-color);
    padding: 0 10px;
    border: 1px solid;
    border-color: light-dark(var(--secondary-color-highlight), rgb(110, 123, 141));
    font-size: 12px;
    cursor: pointer;

    &.p-chip-highlight {
      border-color: var(--clr-info);
    }

    &.p-chip-readonly {
      cursor: default;
      border-color: transparent;
    }

    &.error-chip {
      background: light-dark(#FFECEC, var(--surface-bg));
      border: 1px solid var(--clr-danger) !important;
      outline: unset !important;
      box-shadow: unset !important;

      * {
        color: light-dark(var(--clr-danger), var(--text-color)) !important;
        fill: light-dark(var(--clr-danger), var(--text-color)) !important;
      }
    }
  }

  .pending-task-creation-name {
    .finished {
      text-decoration: line-through;
    }
  }
}


li:has(.dropdown-prev-selected) {
  background: var(--surface-bg) !important;
}

.input-panel-with-chip {
  position: relative;
  overflow: hidden;

  .p-chip {
    position: absolute !important;
    top: 50%;
    left: 0;
    z-index: 10;
    transform: translateY(-50%);
  }
}

.follower-chips-container {
  display: inline-flex;
  gap: 0px;
  white-space: nowrap;
  overflow: hidden;

  .follower-chip {
    width: fit-content;
    aspect-ratio: 1 / 1;
    border: none;
    background: var(--surface-bg);
  }
}

body:has(#pending-tasks-content) {
  .p-tooltip-text {
    span[class^="comment-button"] {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }

  .p-overlaypanel:has(.comment-modal) {
    width: 362px;

    .comment-modal {
      width: 100%;
    }
  }
}

.dropdown-follower-inplace-with-chips {
  // height: 28px;
  height: 26px;

  // .follower-chips-container {
  //   display: unset;
  // }
}

.custom-sort-overlay-panel.p-menu.p-menu-overlay {
  padding: 0;
  overflow: hidden;
  width: 215px;
  background: light-dark(var(--primary-color-text), var(--surface-highlight));

  .p-menu-list {
    .p-menuitem:first-child {
      .p-menuitem-link {
        border-radius: 6px 6px 0 0;
      }
    }

    .p-menuitem:last-child {
      .p-menuitem-link {
        border-radius: 0 0 6px 6px;
      }
    }

    .p-menuitem-link {

      font-family: 'Mulish';
      padding: 12px 10.5px;
      gap: 7px;
      font-size: 14px;

      .p-menuitem-text {
        color: light-dark(#4F5555, var(--text-color));
      }

      .p-menuitem-icon {
        margin: 0;
        font-size: 17px;
        color: var(--text-color);

        &.svg {
          transform: scale(1.5);
        }
      }

      &:hover {
        background: light-dark(#e9ecef, var(--text-color-secondary));

        .p-menuitem-icon {
          color: var(--text-color);
        }
      }

    }
  }


  .p-submenu-header {
    &.p-menu-separator {
      padding: 0;
      margin: 0;
      border-top-color: light-dark(var(--surface-shadow), var(--secondary-color-dark));
    }
  }
}

.custom-sort-overlay-trigger {
  font-family: 'Mulish', sans-serif;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  width: fit-content;
}

.finished-pending-task-list.p-panel.p-panel-toggleable {

  .p-panel-content,
  .p-panel-header {
    background: transparent;
    border: none;
    padding: 0;
  }
}

.pending-tasks-list {
  .pending-task-wrapper {
    padding-right: 7px;
    padding-left: 7px;
  }

  .p-datatable-thead {
    display: none;
  }

  .p-datatable .p-datatable-tbody>tr>td {
    border-color: light-dark(var(--surface-300), #555e6a);
  }

  .non-reorderable {
    opacity: 0.5 !important;

    i {
      cursor: auto !important;
    }
  }

  @media screen and (max-width: 960px) {
    &.p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr[role=row] {
      display: flex;
      justify-content: space-between;

      .task-wrapper,
      .task-data {
        width: 100% !important;
      }

      td {
        display: flex;
        // width: unset !important;

        &:has(.task-wrapper) {
          width: 100% !important;
        }
      }
    }

    .pending-task-data .pending-task-chips {
      height: unset !important;
    }
  }

  .p-datatable-tbody {
    tr {
      display: flex !important;
      width: 100%;
      min-width: 100%;

      td {
        &:last-child {
          width: 100% !important;
        }

        display: flex;
        align-items: center;
      }

      &:last-child td {
        margin-bottom: var(--fill-drop-area-task-list);

        @media (max-height: 600px) {
          margin-bottom: 0;
        }
      }

      &:first-child td {
        margin-top: 14px;
      }
    }
  }

  .p-datatable-dragpoint-bottom>td {
    box-shadow: inset 0px -2px 0px 0 var(--primary-color-200) !important;
  }
}


.reorder-column {
  max-width: 0;
  width: 0;
  padding: 0 !important;
  opacity: 0;
  overflow: hidden;

  i {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.animate-in {
  animation: slideIn 0.320s ease forwards;
}

.animate-out {
  animation: slideOut 0.320s ease forwards;
}

@keyframes slideIn {
  0% {
    max-width: 0;
    width: 0;
    padding: 0;
    opacity: 0;
  }

  100% {
    max-width: 37px;
    width: 37px;
    padding: 7px;
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    max-width: 37px;
    width: 37px;
    padding: 7px;
    opacity: 1;
  }

  100% {
    max-width: 0;
    width: 0;
    padding: 0;
    opacity: 0;
  }
}

.pending-tasks-list tbody tr[role="row"] {
  background: light-dark(var(--primary-color-text), var(--surface-card));

  // Previous sibling 
  &:has(+ tr:hover) {

    td {
      border-bottom-color: transparent !important;
    }
  }

  // Next sibling
  &:hover+tr {
    td {
      border-top-color: transparent !important;
    }
  }

  &:first-child td {
    border-top: 1px solid transparent;
  }

  td {
    border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
    border-bottom: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
    background: light-dark(var(--primary-color-text), var(--surface-card)) !important;
  }

  &:last-child td {

    &,
    &:hover {
      border-bottom-width: 1px !important;
    }
  }

  // Hovered row styles
  &:hover {
    overflow: visible;
    position: sticky;
    z-index: 2;

    td {
      border-color: transparent !important;
      border-top: 1px solid light-dark(#67B8E9, #023E66) !important;
      border-bottom: 1px solid light-dark(#67B8E9, #023E66) !important;
      // box-shadow: 0 1px 0 light-dark(#67B8E9, #023E66) inset, 0px -1px 0 light-dark(#67B8E9, #023E66) inset;
      background: light-dark(#cdecfe, #0A5587) !important;

      .difference-reason-chip {
        background: light-dark(#cdecfe, #0A5587);
      }
    }

    .task-data-chips {
      >div:nth-child(1) {
        background-color: #a4aab2;
      }

      >div:nth-child(2) .p-chip {
        background: light-dark(#2896d8, var(--clr-info));
        color: white;
      }
    }
  }
}

.mis-tareas.p-tabview-panel {

  &,
  &>div {
    max-height: calc(100vh - 192px);

  }
}


.pending-task-list-wrapper {

  &.p-card,
  .p-card-body,
  .p-card-content {
    max-height: 100%;
    min-height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .pending-task-list-content {
    display: grid !important;
    grid-template-rows: repeat(auto-fill, minmax(fit-content, 1fr));
    min-height: 0;

    >div {
      min-height: 0;
      max-height: 100%;
      overflow-y: auto;
      scrollbar-gutter: stable;
    }

    .pending-tasks-list {
      overflow-x: auto;

      @media(max-width: 950px) {
        .p-datatable-wrapper {
          width: fit-content;
        }
      }
    }

    .finished-pending-task-list {
      overflow-x: auto;

      @media(max-width: 950px) {
        .p-toggleable-content {
          width: fit-content;
        }
      }
    }
  }

  .finished-pending-task-list {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    min-height: 30px !important;
    max-height: fit-content !important;

    .p-panel-header {
      position: sticky;
      top: 0;
      background: light-dark(var(--primary-color-text), var(--surface-card)) !important;
      z-index: 100;
      border-radius: unset;

      .p-panel-header-icon {
        &:hover {
          background: light-dark(#e9ecef, var(--text-color-secondary)) !important;

          * {
            color: var(--text-color);
          }
        }
      }

      *:focus {
        box-shadow: unset !important;
      }
    }

    .p-panel-content .pending-task-wrapeer {
      border-top: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
      border-bottom: 1px solid light-dark(#dee2e6, var(--secondary-color-dark));
    }
  }
}

body:has(#pending-tasks-content) {
  overflow: hidden;
}

.pending-tasks-list {
  .p-datatable-tbody {
    tr {
      td {
        margin: 0 auto !important;
      }
    }
  }
}

.pending-task-view-selector.p-selectbutton {
  .p-highlight.p-button {
    border-color: light-dark(var(--primary-color-highlight), var(--primary-color-200));
  }
}


.p-toast {
  .p-toast-message-icon {
    font-size: 36px;
    padding: 6px;
  }

  .p-toast-message {
    max-width: 492px;
  }
}