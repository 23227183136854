.p-divider-solid.p-divider-horizontal:before {
    border-top-style: solid;
}

// Divider
.p-divider-solid.p-divider-horizontal:before,
.p-divider-solid.p-divider-vertical:before{
  border: unset;
  background: light-dark(var(--surface-shadow), var(--secondary-color-dark));
}

.p-divider-vertical:before {
  width: 2px;
}
.p-divider-horizontal {
  &.md:before {
    height: 2px;
  }
  &.sm:before {
    height: 1px;
  }
  &.lg:before {
    height: 3px;
  }
}