#client-form {

  .field label,
  .field>.flex span {
    font-family: Mulish !important;
    color: light-dark(var(--secondary-color-dark), var(--text-color));
    line-height: 150%;
    font-weight: 600;
  }

  .field label {
    margin-bottom: 3.5px;
  }

  .p-tabview-nav,
  .p-tabview-panels {
    padding: 0 !important;
  }

  .p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav {
    margin: unset;
  }

  li.p-tabview-ink-bar {
    margin-left: unset;
  }

  // .p-card-content {
  //   overflow: hidden;
  // }
  .p-blockui-container:has(.p-component-overlay) {
    opacity: 0.85;
    pointer-events: none;
    transition: opacity 0.3s ease;

    .p-component-overlay {
      background-color: transparent;
      opacity: 0;
    }
  }

  .module-item {
    &-controls {
      opacity: 0;
      visibility: hidden;
    }

    &:hover &-controls {
      opacity: 1 !important;
      visibility: visible !important;
    }

    font-weight: 600;

    .module-item-drag,
    .module-item-selected {
      height: 26px;
      width: 26px;
      fill: light-dark(var(--primary-color-highlight), #00B4FF);
      border-color: light-dark(var(--primary-color-highlight), #00B4FF);
    }

    &-controls {
      gap: 10px;
      color: light-dark(#023E66, #FFF);

      .pi {
        font-size: 16px;
      }
    }

    &-controls-visible {
      gap: 10px;

      .pi {
        font-size: 16px;
        // color: #023E66;
        color: light-dark(#023E66, #FFF);
      }
    }
  }

  .p-selectbutton .p-button-label {
    font-weight: 400;
  }

  .form-card {

    min-height: calc(100vh - 178px);

    .p-card-body,
    .p-card-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      flex: 1;

      .p-tabview-panels,
      .p-tabview-panel {
        display: flex;
        flex: 1;
        height: 100%;
      }
    }
  }
}

.color-dropdown {
  .p-dropdown-label {
    padding: 0 0.75rem;
    height: 39px;
    display: flex;
    align-items: center;
  }
}

.module-checkbox {
  .p-hidden-accessible:has(input[type="checkbox"]:not(:checked))~div {
    >.p-checkbox-icon::before {
      content: "\e935";
      font-family: 'primeicons';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
      font-size: 10.5px;
    }
  }
}

.module-panel {
  .p-panel-content {
    border: none;
    padding: 0;
  }
}

.autocomplete-suggestions {
  border: 1px solid var(--surface-highlight);
  background-color: var(--surface-card);
  position: absolute;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  width: 95%;

  .p-card-body {
    padding: 0;
  }
}

.suggestion-item {
  padding: 7px 17.5px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: var(--surface-hiver);
}


#clients-table {
  tbody.p-datatable-tbody>tr {
    height: 43px;
  }
}


.create-module-input {
  .module-input {
    font-family: Mulish;
    font-size: 14px;
    color: var(--surface-b);
    font-weight: 600;
    // background: var(--primary-color-highlight);
    background: var(--primary-color-highlight);
    padding: 8px;
    border-radius: 4px;
  }
}

.p-picklist-item:hover .module-item-controls {
  opacity: 1 !important;
  visibility: visible !important;
  transition: 0.2s;
}


.p-picklist-buttons {
  display: flex;

  button {
    width: 32px !important;
    height: 32px;
  }

  button:nth-child(1) {
    order: 2;
  }

  button:nth-child(2) {
    order: 1;
  }

  button:nth-child(3) {
    order: 3;
  }

  button:nth-child(4) {
    order: 4;
  }
}

.module-section {
  .create-module-input {

    .p-inplace-display,
    .p-inplace-content {
      padding: 2px 16px;
      display: flex;
    }

    .p-inplace-content button[aria-label=Close] {
      display: none !important;
    }

    .module-input {
      height: 40px;
      border-radius: 4px;
      width: 100%;
      transition: height 0.3s;
    }

    .p-inplace-content .module-input {
      height: 58px;
      transition: height 0.3s;

      .module-input-controls {
        gap: 10px;
      }
    }
  }

  .custom-pick-list .p-picklist .p-picklist-list .p-picklist-item {
    &.p-highlight {
      background: unset;
      border: unset;

      .module-item {
        // background: #CDECFE;
        // color: #023E66;
        background: light-dark(#CDECFE, var(--primary-color));
        font-weight: 600;

        div>div:has(.module-item-drag) {
          display: none !important;
        }

        .module-item-selected {
          display: block !important;
        }

        .module-item-controls {
          display: none !important;
        }
      }
    }

    .module-item {
      border-radius: 4px;
      // background: #F5F5F5;
      // background: var(--gray-100);
      background: light-dark(var(--gray-100), #535C69);
      border: 1px solid;
      border-color: light-dark(transparent, #6E7B8D);
      color: var(--text-color);
      min-height: 40px;
      padding: 8px;

      &:hover {
        // background: #CDECFE;
        background: light-dark(#CDECFE, var(--primary-color));
      }

      &:has(.module-item-controls-visible) {
        background: light-dark(#CDECFE, var(--primary-color));

        .p-inputtext {
          border-color: #006AA6 !important;
          width: 100%;
        }
      }

      input {
        background: light-dark(var(--surface-highlight), #023E66);
      }
    }

    .module-item-drag {
      cursor: grab;

      path {
        fill: var(--text-color);
      }
    }
  }
}

.p-tabview-nav-content {
  display: flex;
  border-bottom: 1px solid light-dark(#dee2e6, var(--surface-highlight));

  ul.p-tabview-nav {
    border: unset;
    flex: 1;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
  }

  div:has(button[aria-label="Crear proyecto"]) {
    flex: 0 !important;
    width: fit-content;
  }
}

.p-tabview-nav {
  container-type: inline-size;
  container-name: tabnav;
}

@container tabnav (max-width: 700px) {
  .p-tabview-nav:has(:nth-child(3)) li.p-tabview-ink-bar {
    display: none !important;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-2px);
  }

  40% {
    transform: translateX(2px);
  }

  60% {
    transform: translateX(-2px);
  }

  80% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s ease-in-out;
}

#clients-table {
  .p-frozen-column {
    z-index: 1;
  }

  .p-datatable-thead {
    z-index: 2;
  }

  .p-datatable-scrollable {
    .p-datatable-thead {
      display: table-header-group !important;
    }

    .p-datatable-tbody {
      display: table-row-group !important;
    }

    .p-datatable-row-expansion {
      display: none !important;
    }

    tr {
      display: table-row !important;
    }

    td {
      display: table-cell !important;
    }
  }
}

.module-section {
  .p-inline-message {
    background-color: transparent !important;

    .p-inline-message-text {
      width: 100%;
    }
  }
}