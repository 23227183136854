.custom-pick-list {
  .p-picklist {
    .p-picklist-header {
      background-color: transparent;
      border-bottom-color: transparent;
      font-family: Cairo;
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 4px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
    .p-picklist-filter-container {
      border-top-color: transparent;
      border-top: unset;
      padding-top: 4px;
      padding-bottom: 4px;
      padding: 4px 16px;
    }
    .p-picklist-list {
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .p-picklist-list-wrapper {
      background: light-dark(#fff, #24292f);
    }
    .p-picklist-list,
    .p-picklist-filter-container,
    .p-picklist-header {
      border-color: #E6E6E6;
    }

    .p-picklist-list-wrapper {
      height: 420px;
      overflow: hidden;
      border: 1px solid;
      border-color: light-dark(#E6E6E6, transparent);
      border-radius: 8px;
      & > * {
        border: none!important;
      }
    }

    .p-picklist-list {
      border-top-color: transparent;
      padding-top: 14px;
      padding-bottom: 34px;
      position: relative;
      overflow-y: auto;
      transition: overflow 0.3s;
      .p-picklist-item {
        padding: 2px 16px;
        &:hover {
          background: light-dark(#CDECFE, var(--primary-color));
        }
        &:hover {
          background: unset!important;
        }
        &:focus {
          box-shadow: unset!important;
        }
        &.p-highlight {
          background: unset!important;
          border: unset!important;
        }
      }
    }
  }
}
