.custom-empty-message {
  min-height: 11rem;

  .icon-container * {
    color: light-dark(rgb(85, 94, 106), var(--text-color));
    fill: light-dark(rgb(85, 94, 106), var(--text-color));
  }

  .message-title,
  .message-content {
    color: light-dark(rgb(85, 94, 106), var(--text-color));
    fill: light-dark(rgb(85, 94, 106), var(--text-color));
  }
}