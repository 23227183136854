.p-dialog{
  // background-color: white;
  .p-dialog-footer {
    margin-top: 1rem;
  }

  &.modal-max-height{
    max-height: 600px;
  }
}

// Dialog form
.p-dialog-content {
  padding-bottom: 14px!important;
}
.p-dialog .p-dialog-footer {
  margin-top: 0;
}
.p-dialog-footer::before {
  min-width: 100px;
  min-height: 1px ;
  // background: var(--surface-shadow);
  background: light-dark(var(--surface-shadow), var(--secondary-color-dark));
  content: "";
  display: block;
  margin-bottom: 1rem;
}