.p-tieredmenu.p-tieredmenu {
    background: light-dark(#fff, #24292f);
    border: none;

    .p-submenu-list {
        background: light-dark(#fff, #24292f);
    }

    .p-menuitem-link {

        .p-menuitem-text,
        .p-menuitem-icon,
        .p-submenu-icon {
            color: light-dark(#4F5555, #F8F9FA);
        }
    }

    .p-menuitem-link:not(.p-disabled):hover {

        .p-menuitem-text,
        .p-menuitem-icon,
        .p-submenu-icon {
            color: light-dark(#4F5555, #F8F9FA);
        }
    }

    .p-menuitem.p-menuitem-active>.p-menuitem-link {

        .p-menuitem-text,
        .p-menuitem-icon,
        .p-submenu-icon {
            color: light-dark(#4F5555, #F8F9FA);
        }
    }

    .p-menuitem-link:not(.p-disabled):hover {
        background: light-dark(#f3f7fc, #33383f);
    }


    .p-menuitem-active.p-menuitem>a {
        background: light-dark(#f3f7fc, #33383f);
    }


}