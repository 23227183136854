.rows-desglose--margen{
    padding-left: 3rem;
    padding-right: 1rem;
}

.table-expandable--margin thead tr th:nth-child(1){
    padding-left: 3.5rem;
    padding-right: 2rem;
}

.table-expandable--margin thead tr th:nth-child(n+2){
    padding-left: 0rem;
    padding-right: 1.5rem;
}

.table-units-remove-margin{
    margin: 0;
}

//Cosecha
.input-number-cell--cajas{
    width: 5rem;
}

//tooltip info

.question-icon-tooltip{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px var(--surface-700) solid;
    border-radius: 50%;
    color: var(--surface-700);
    min-width: auto;
    width: 14px;
    height: 14px;

}

.question-icon-tooltip svg{
    padding: 0;
    margin: 0;
    width: 10px;
    height: 10px;
}
.alert-amount{
    display: flex;
    align-items: center;
}
.alert-amount-container{
    min-width: 5rem;
    display: flex;
    align-items: center;
}
.alert-amount--suffix-margin{
    margin-left: 2.5px;
}
.alert-amount--active{
    color: var(--clr-red-700);
}

.arrow-alert--rotate{
    rotate: 180deg;
}

.p-datatable-row-expansion > td{
    padding: 0 !important;
}

.table-totales-color{
    background-color: #E5EEF7 !important;
}

.background-internal-table .p-datatable-tbody tr{
    background-color: var(--gray-clr-gray-100, #F5F5F5);
}
//compra-externa
.column-budget-nombre{
    width: 15.8117%;
}


.column-expandable{
    min-width: 56px;
    width: 0.03583595%;
}
.column-variedad-nombre{
    width: 15.8117%;
}
.column-totalSemanalWeek{
    width: 8.72265%
}
.column-totalDiarioWeek{
    width: 8.7633%
}
.column-totalRealWeek{
    width: 8.7633%
}
.column-totalKGM2Semanal{
    width: 7.274%
}
.column-totalKGM2Diario{
    width: 7.2531%
}
.column-totalKGM2Real{
    width: 7.2531%
}

.column-porcentajesRealVsSemanal{
    width: 11.06795%
}
.column-porcentajesDiarioVsSemanal{
    width: 11.9344%
}
.column-porcentajesRealVsDiario{
    width: 9.57265%
}




@media screen and (max-width: 1661px) {
    .column-budget-nombre{
        width: calc(110.45px + (100vw - 1375px) * 0.297972);
    }

    .column-variedad-nombre{
        width: calc(110.45px + (100vw - 1375px) * 0.297972);
    }
    .column-totalSemanalWeek{
        width: calc(108.06px +  (100vw - 1375px) * 0.00493)
    }
    .column-totalDiarioWeek{
        width: calc(94.23px + (100vw - 1375px) * 0.0534615)
    }
    .column-totalRealWeek{
        width: calc(94.23px + (100vw - 1375px) * 0.0534615)
    }
    .column-totalKGM2Semanal{
        width: calc(87.06px + (100vw - 1375px) * 0.0144755)
    }
    .column-totalKGM2Diario{
        width: calc(68.25px + (100vw - 1375px) * 0.0772027)
    }
    .column-totalKGM2Real{
        width: calc(57.97px + (100vw - 1375px) * 0.1119230)
    }
    .column-porcentajesRealVsSemanal{
        width: calc(98px + (100vw - 1375px) * 0.1386013)
    }
    .column-porcentajesDiarioVsSemanal{
        width: calc(98px + (100vw - 1375px) * 0.1753846)
    }
    .column-porcentajesRealVsDiario{
        width: calc(98px + (100vw - 1375px) * 0.0752447)
    }
}

@media screen and (max-width: 1375px) {
    .column-budget-nombre{
        width: 110.45px;
    }
    
    .column-variedad-nombre{
        width: 110.45px;
    }
    .column-totalSemanalWeek{
        width: 108.06px;
    }
    .column-totalDiarioWeek{
        width: 94.23px;
    }
    .column-totalRealWeek{
        width: 94.23px;
    }
    .column-totalKGM2Semanal{
        width: 87.06px;
    }
    .column-totalKGM2Diario{
        width: 68.25px;
    }
    .column-totalKGM2Real{
        width: 57.97px;
    }
    .column-porcentajesRealVsSemanal{
        width: 98px;
    }
    .column-porcentajesDiarioVsSemanal{
        width: 98px;
    }
    .column-porcentajesRealVsDiario{
        width: 98px;
    }
}

//suffix componete mostrar datos en card
.suffix-unit {
    font-size: .75rem;
    color: #757575;
}

//pronosticos
.table-totalKGM2-variedades-multiples .p-datatable-thead{
    background-color: #000;
}

.card-captura-pronosticos{
    position: relative;
}

.message-captura-pronosticos .p-message{
    position: absolute;
    width: auto;
    height: 3rem;
    top: 0;
    right: 17.5px;
}
.message-captura-pronosticos .p-message-wrapper{
    padding: 10px 20px 10px 20px;
}

.color-gray-tabla-registro-medleys{
    background-color: #F5F5F5;
}

//compra externa registro
.input-number-cell--kg{
    max-width: 8.5rem;
}

//registro de pallets compra externa

.registro-pallets-container{
    padding: 0.8rem;
    .p-datatable-footer{
        background-color: var(--surface-a);
    }
}

.registro-pallets {
    width: 100%;
    display: flex;
    align-items: center;
}
.registro-pallets-pallet-nombre{
    display: flex;
    justify-content: flex-start;
    flex-grow: 2;
}
.registro-pallets-input-peso{
    display: flex;
    align-items: center;
}
.registro-pallets-input-cantidad{
    display: flex;
    align-items: center;
}

.input-peso-label{
    margin-right: 0.8rem;
}
.input-cantidad-label{
    margin-right: 0.8rem;
}

.registro-totales-proveedor{
    display: flex;
    justify-content: flex-end;
    font-size: 1.35rem;
    margin-top: 0.4rem;
}

.text-bold-600{
    font-weight: 600;
}

.text-bold-700{
    font-weight: 700;
}

.promedio-caja-column{
    display: flex;
    align-items: center;
}
.nombre-budget-column{
    display: flex;
    align-items: center;
    .tabmenu-text{
        margin: 0;
        margin-right: 1rem;
    }
}

//menu intermedio
.switch-between-two-filters{
    display: flex;
    justify-content: space-between;
    .field{
        width: 206px;
    }
    .p-selectbutton{
        display: flex;
        align-self: flex-end;
        margin-bottom: 0.5rem;
        .p-button{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 1.7rem;
            width: 2rem;
            padding: 0.7rem;
            .MuiSvgIcon-root{
                width: 1rem;
            }
        }
    }
    
}

.button-active-single {
    color: var(--clr-brand);
    rect{
        stroke: #fff;
    }
}
.button-desactive-single {
    color: #fff;
}
.button-active-multi {
    color: var(--clr-brand);
    rect{
        stroke: #fff;
    }
}
.button-desactive-multi {
    color: #fff;
    rect{
        stroke: var(--clr-gray-500);
    }
}

.badge-lort-single{
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.table-registro-cosecha{
    th{
        background-color: var(--surface-100) !important;
    }
    .p-rowgroup-header{
        background-color: var(--surface-100) !important;
    }
}

.table-headers-color{
    background-color: var(--surface-100) !important;
}


//empleados
.empleados-table{
    .p-multiselect-label{
        display: flex;
    }
}

//reporte de calidad defectos
.column-dia{
    width: 5rem !important;
}

.column-defecto{
    width: 5rem !important;
}

.column-total{
    width: 5rem !important;
}

//reporte de calidad 

.comentarios-container{
    height: 12rem;
    overflow: auto;
}

.empacadora-template{
    height: 49px;
    padding: 1rem 14px 1rem 14px;
}

.indicadores-template{
    min-width: 5rem;
    padding: 1rem 14px 1rem 14px;
}

.pesos-diarios-table{
    .p-column-title{
        text-align: center;
    }
    .p-column-header-content{
        justify-content: center;
    }
    .p-datatable-tbody:nth-child(1){
        background-color: var(--surface-100) !important;
        padding: 0 !important;
    }
    td{
        padding: 0 !important;
    }
}
.column-presentaciones{
    width: 20%;
    min-width: 10%;
    max-width: 20%;
}

.column-empacadora{
    width: 12%;
    min-width: 12%;
    max-width: 12%;
}

.column-porcentaje{
    width: 9%;
    min-width: 100px;
}


@media only screen and (max-width: 961px) {
    .pesos-diarios-table{
        .p-column-title{
            text-align: left;
            width: 9rem;
            padding-left: 1rem;
        }
        .indicadores-template{
            width: 9rem;
        }
        .empacadora-template{
            width: 9rem;
            min-width: 9rem;
        }
    }
    
  }

  /* Requisiciones de mantenimiento */
.menu-item{
    display: block;
    padding: 0.75rem 1.25rem;
}


/* Modal detalle de requisición de mantenimiento */

.modal-detalle{
    font-weight: 700;
    font-size: 1.25rem;
}
